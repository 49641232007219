import React from 'react';
import Layout from '../../../../components/Layout';
import { Container, Row, Column } from '../../../../components/Grid';
import Section from '../../../../components/Section';
import { Link } from 'gatsby';
import Video from '../../../../components/Video';
import Icon from '../../../../components/Icon';

const TopYoungScientist = () => (
  <Layout
    title="What is it like to be America's Top Young Scientist?"
    className="annual-challenge has-video"
  >
    {/* Intro Title and Video */}
    <Section>
      <Container>
        {/* Breadcrumbs */}
        <ul className="breadcrumbs no-hero">
          <li className="breadcrumb">
            <Link to="/annual-challenge/about-the-challenge">
              Annual Challenge
            </Link>
          </li>
          <li className="breadcrumb">
            <Link to="/annual-challenge/event-gallery-videos">
              Inside the Challenge
            </Link>
          </li>
          <li className="breadcrumb">
            <Link to="/annual-challenge/event-gallery-videos">Videos</Link>
          </li>
          <li className="breadcrumb active">
            What is it like to be America's Top Young Scientist?
          </li>
        </ul>
        <Row>
          <Column size={10} offset={1} className="no-hero">
            <h1 className="page-title">
              What is it like to be America's Top Young Scientist?
            </h1>
            {/* Video Container */}
            <div className="video-container__with-pager">
              <div className="videoWrapper">
                <Video
                  guidSrc="9b708afb-de27-4082-b11c-a823c35775cf"
                  className="de-vid"
                ></Video>
              </div>
              <Link to="../why-we-love-science" className="next">
                <Icon name="arrowrightgray" />
              </Link>
            </div>
            <h3>
              Hear from Top Young Scientist Gitanjali Rao about what it was like
              to win the 2017 Young Scientist Challenge.
            </h3>
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default TopYoungScientist;
